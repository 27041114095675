<template>
  <v-dialog
    v-model="show"
    width="auto"
    @click:outside="init()"
    @keydown="$event.key === 'Escape' ? init() : ''"
  >
    <v-card class="log-wrap">
      <p class="tit">
        {{ batch != null ? `메모일괄등록(${batch.length})` : '고객 메모' }}
        <span class="sub" v-if="batch == null">{{ target.name }}</span>
      <v-chip class="float-right"
          label
          link
          depressed
          color="error"
          @click="deleteSelectedMemo()"
          v-if="batch == null"
      >
        선택메모삭제
      </v-chip>
      </p>
      <div v-if="batch != null"></div>
      <div
        class="log"
        v-else-if="data != null && data.length > 0"
      >
        <div
          class="log-row"
          v-for="(row, index) in data"
          :key="index"
        >
          <span class="log-span memo-span d-flex align-center"
           :class="row.category">
          <v-checkbox
            v-model="checked[index]"
          >
          </v-checkbox>
           {{ row.memo }}</span>
          <div class="info-box d-flex">
            <div>
              <p>{{ makeCreatorToFullName(row.creator) }}</p>
              <p class="date">{{ time.makeLocalTime(row.date, 'sec') }}</p>
            </div>
            <div class="d-flex align-center">
              <v-tooltip
                top
                color="#607D8B"
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="ml-1"
                    v-on="on"
                    v-bind="attrs"
                    @click="delMemo(target, index)"
                  >
                    <v-icon color="#FF8A65" size="18px">mdi-delete-circle-outline</v-icon>
                  </v-btn>
                </template>
                <span>삭제</span>
              </v-tooltip>
            </div>
          </div>
        </div>
      </div>
      <div
        class="log"
        v-else
      >
        <p class="log-row"><span>메모가 없습니다.</span></p>
      </div>
      <div class="memo-area" :class="batch != null ? '' : 'mt-4'">
        <v-textarea
          :class="memoCategory.value"
          v-model="memo"
          background-color="#f2f2f2"
          :autofocus="true"
          placeholder="메모내용을 입력해주세요."
          dense
          auto-grow
          flat
          hide-details
          :color="memoCategory.color"
          outlined
          solo
          style="font-size: .9rem"
          ref="areaFocus"
        ></v-textarea>

        <div class="d-flex align-center justify-center mt-2">
          <v-radio-group
            dense
            row
            hide-details
            class="mt-0 pt-0 mr-10"
            v-model="memoCategory.value"
            v-for="(val, key) in categoryColor"
            :key="key"
          >
            <v-radio class="mr-2" :label="val.label"
              :value="val.value" :color="val.color"
              @change="updateMemoCategory(val)"></v-radio>
          </v-radio-group>
          <v-btn
            color="primary"
            elevation
            :disabled="memo.trim().length <= 0"
            @click="updateMemo()"
          >
            등록
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import comm from '@/util/comm';
import time from '@/util/time';

export default {
  name: 'DlgCustomerMemo',
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    target: {
      type: Object,
      required: true,
    },
    func: {
      type: Function,
    },
    batch: {
      type: Array,
      required: false,
    },
  },
  data: () => ({
    comm,
    time,
    memo: '',
    groups: [],
    staffs: [],
    checked: [false],
    textColor: '',
    memoCategory: { label: '리딩', value: 'leading', color: 'info' },
    categoryColor: [
      { label: '리딩', value: 'leading', color: 'info' },
      { label: '코인', value: 'coin', color: '#6aa33b' },
      { label: '기타', value: 'etc', color: 'accent' },
      { label: '레퍼럴', value: 'referral', color: 'error' },
    ],
  }),
  computed: {
    ...mapGetters({
      userInfo: 'auth/userInfo',
      memoDlg: 'dialog/memo',
    }),
  },
  methods: {
    ...mapMutations({
      progress: 'dialog/progress',
      confirm: 'dialog/confirm',
    }),
    ...mapActions({
      alert: 'dialog/alert',
    }),
    init() {
      this.$emit('update:show', false);
      this.memo = '';
      this.checked = [];
    },
    getDefaultData() {
      this.$socket.emit('groups.list.get', {
        page: 1,
        itemsPerPage: 0,
        sortBy: ['no'],
        sortDesc: [false],
        filters: [],
      }, (resp) => {
        if (resp.result === 'success') {
          this.$socket.emit('users.staffs.list.get', {
            page: 1,
            itemsPerPage: 0,
            sortBy: [],
            sortDesc: [],
            filters: [],
          }, (res) => {
            if (res.result === 'success') {
              this.groups = resp.items.groups;
              this.staffs = [];
              res.items.forEach((r) => {
                const groupName = comm.getGroupFullPath(this.groups, r.groupNo, '그룹없음 ');
                this.staffs.push({
                  id: r.id,
                  fullName: `${groupName}${r.name}`,
                });
              });
            } else {
              this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${res.code})`]);
              console.error(res);
              this.init();
            }
          });
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.init();
        }
      });
    },
    makeCreatorToFullName(creator) {
      const row = this.staffs.find((r) => r.id === creator);
      return row?.id === undefined ? '시스템' : row.fullName;
    },
    updateMemo() {
      if (this.memo.trim().length > 300) {
        this.alert(['error', '최대 300자까지 등록가능합니다.']);
      } else if (this.batch == null) {
        const reg = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣|a-z]/;
        if (this.memoCategory.value === 'referral' && reg.exec(this.memo) !== null) {
          this.alert(['error', '레퍼럴의 경우 숫자만 입력 가능합니다.']);
        } else {
          this.progress(true);
          const memos = [...this.data];
          memos.unshift({
            memo: this.memo,
            creator: this.userInfo.id,
            date: time.makeUTCTime(),
            category: this.memoCategory.value,
          });

          this.$socket.emit('users.customer.update', {
            items: [{
              id: this.target.id,
              memo: JSON.stringify(memos),
            }],
          }, (resp) => {
            if (resp.result === 'success') {
              if (this.memoCategory.value === 'referral') {
                this.$socket.emit('users.customer.memo.referral.update', {
                  items: [{
                    id: this.target.id,
                    memo: this.memo,
                    manager: this.userInfo.id,
                  }],
                }, (response) => {
                  if (response.result === 'success') {
                    this.$emit('update:data', memos);
                    this.alert(['success', '메모가 등록되었습니다.']);
                    this.memo = '';
                    this.func();
                    this.progress(false);
                  } else {
                    this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${response.code})`]);
                    console.error(response);
                    this.loading = false;
                  }
                });
              } else {
                this.$emit('update:data', memos);
                this.alert(['success', '메모가 등록되었습니다.']);
                this.memo = '';
                this.func();
                this.progress(false);
              }
            } else {
              this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
              console.error(resp);
              this.progress(false);
            }
          });
        }
      } else {
        const reg = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣|a-z]/;
        if (this.memoCategory.value === 'referral' && reg.exec(this.memo) !== null) {
          this.alert(['error', '레퍼럴의 경우 숫자만 입력 가능합니다.']);
        } else {
          this.progress(true);
          this.$socket.emit('users.customer.memo.update', {
            items: this.batch,
            memo: this.memo,
            category: this.memoCategory.value,
          }, (resp) => {
            if (resp.result === 'success') {
              if (this.memoCategory.value === 'referral') {
                this.$socket.emit('users.customer.memo.referral.batch.update', {
                  items: this.batch,
                  memo: this.memo,
                  manager: this.userInfo.id,
                }, (response) => {
                  if (response.result === 'success') {
                    this.alert(['success', '메모가 등록되었습니다.']);
                    this.init();
                    this.func();
                    this.progress(false);
                  } else {
                    this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${response.code})`]);
                    console.error(response);
                    this.loading = false;
                  }
                });
              } else {
                this.alert(['success', '메모가 등록되었습니다.']);
                this.init();
                this.func();
                this.progress(false);
              }
            } else {
              this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
              console.error(resp);
              this.progress(false);
            }
          });
        }
      }
    },
    delMemo(target, index) {
      const func = (event) => {
        if (event.key === 'Enter' || event.type === 'click') {
          this.confirm({ show: false });
          const userId = target.id;
          this.$socket.emit('users.customer.memo.delete', { id: userId, memoSeq: index }, (resp) => {
            if (resp.result === 'success') {
              this.alert(['success', '삭제되었습니다.']);
              // this.init();
              this.getcustomerMemo(target.id);
              this.func();
              this.progress(false);
            } else {
              this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
              console.error(resp);
            }
          });
          document.removeEventListener('keydown', func);
        }
      };

      this.confirm({
        show: true,
        func,
        msg: `삭제된 메모는 복구할수 없습니다.
          <br/><br/>정말 삭제할까요?`,
        btnText: '삭제',
        color: 'error',
      });

      document.addEventListener('keydown', func);
    },
    updateMemoCategory(category) {
      if (this.categoryColor.filter((e) => e.value === category.value)) {
        this.memoCategory = category;
        this.$refs.areaFocus.focus();
      }
    },
    filterCategory(item) {
      const filtered = this.categoryColor.filter((c) => c.value === item);
      return filtered.color;
    },
    getcustomerMemo(id) {
      this.progress(true);
      this.$socket.emit('users.customer.memo.get', { id }, (resp) => {
        if (resp.result === 'success') {
          this.$emit('update:data', resp.items[0].memo);
          this.progress(false);
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
        }
      });
    },
    deleteSelectedMemo() {
      const userId = this.target.id;
      const memo = [];
      for (let i = 0; i < this.checked.length; i += 1) {
        if (this.checked[i] === true) {
          memo.push(i);
        }
      }
      this.$socket.emit('users.customer.memo.selectDel', { id: userId, memoArray: memo }, (resp) => {
        if (resp.result === 'success') {
          this.alert(['success', '삭제되었습니다.']);
          this.checked = [];
          // this.init();
          this.getcustomerMemo(this.target.id);
          this.func();
          this.progress(false);
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
        }
      });
    },
  },
  watch: {
    show: {
      immediate: true,
      handler(val) {
        if (val === true) this.getDefaultData();
      },
    },
  },
};
</script>

<style scoped lang="scss">
.log-wrap {
  padding: 15px;
  .tit {
    font-size: 1.1rem;
    font-weight: 500;
    padding-bottom: 10px;
    margin: 0;
  }
  .sub {
    font-size: 0.9rem;
    margin-top: -8px;
    margin-left: 4px;
    margin-bottom: 6px;
  }
  .log {
    max-height: 500px;
    overflow: auto;
    .log-row {
      background-color: #f2f2f2;
      padding: 10px;
      margin: 0;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-width: 400px;
      & + .log-row {
        margin-top: 4px;
      }
      span {
        font-size: 0.9rem;
        color: #555;
        & + .info-box {
          margin-left: 40px;
        }
      }
      span.log-span {
        color: #262626;
        font-weight: 500;
        &.memo-span {
          white-space: pre-wrap;
          max-width: 400px;
        }
      }
    }
    .info-box {
      p {
        text-align: right;
        font-size: 0.8rem;
        margin: 0;
        color: #222;
        &.date {
          color: #888;
        }
      }
    }
  }
  .memo-area {
    min-width: 400px;
  }
  .leading {
    color: #2196F3 !important;
  }
  .coin {
    color: #6aa33b !important;
  }
  .etc {
    color: #AA6063 !important;
  }
  .referral {
    color: #FF5252 !important;
  }
}
</style>
