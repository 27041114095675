<template>
  <v-dialog
    scrollable
    v-model="show"
    fullscreen
    persistent
    content-class="width-fix-dialog"
    v-if="item !== null"
    no-click-animation
  >
    <v-card class="rounded-0">
      <div class="title-bar">
        <div class="tit">
          <v-menu offset-y v-if="
              type === 0
              && checkFuncPermission('status')
            "
          >
            <template #activator="{ on, attrs }">
              <v-chip small link outlined
                class="pr-2 mt-1"
                v-bind="attrs"
                v-on="on"
                :color="item.status === 1
                  ? 'info'
                  : (item.status === 2 ? 'success' : '')"
              >
                <span v-if="item.status === 1">진행중</span>
                <span v-else-if="item.status === 2">처리완료</span>
                <span v-else>미확인</span>
                <v-icon small class="ml-1">mdi-pencil</v-icon>
              </v-chip>
            </template>
            <v-list>
              <v-list-item dense nav link v-if="item.status !== 0"
                @click="updateStatus(item.no, 0)"
              >
                <v-list-item-title>미확인으로 변경</v-list-item-title>
              </v-list-item>
              <v-list-item dense nav link v-if="item.status !== 1"
                @click="updateStatus(item.no, 1)"
              >
                <v-list-item-title>진행중으로 변경</v-list-item-title>
              </v-list-item>
              <v-list-item dense nav link v-if="item.status !== 2"
                @click="updateStatus(item.no, 2)"
              >
                <v-list-item-title>처리완료로 변경</v-list-item-title>
              </v-list-item>
              <v-list-item dense nav link
                @click="getHistories(item.no)"
              >
                <v-list-item-title>상담처리 이력보기</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-chip v-else-if="type === 0" small
            :color="item.status === 1
              ? 'info'
              : (item.status === 2 ? 'success' : '')"
            class="tit-chip mt-1"
          >
            <span v-if="item.status === 1">진행중</span>
            <span v-else-if="item.status === 2">처리완료</span>
            <span v-else>미확인</span>
          </v-chip>
          <p class="tit-text">{{ item.title }}</p>
          <v-btn icon @click="init" large color="black"><v-icon>mdi-close</v-icon></v-btn>
        </div>
        <div class="tit-info">
          <div class="info-item">
            <v-chip small label outlined class="pr-2 pl-2 mr-1">등록인</v-chip>
            <span class="info-name">
              {{ `${comm.getGroupFullPath(groups, item.creatorGroupNo)} ${item.creatorName}` }}
            </span>
          </div>
          <div class="info-item">
            <v-chip small label outlined class="pr-2 pl-2 mr-1">등록일시</v-chip>
            <span class="info-name">{{ time.makeLocalTime(item.created) }}</span>
          </div>
          <div class="info-item" v-if="type === 0">
            <v-chip small label outlined class="pr-2 pl-2 mr-1">담당자</v-chip>
            <span class="info-name">
              {{ `${comm.getGroupFullPath(groups, item.managerGroupNo)} ${item.managerName}` }}
            </span>
          </div>
          <div class="info-item" v-if="type === 0">
            <v-chip small label outlined class="pr-2 pl-2 mr-1">고객</v-chip>
            <v-tooltip top color="black">
              <template #activator="{ on, attrs }">
                <span class="info-name" v-bind="attrs" v-on="on">
                  {{`
                    ${item.customerName}
                    (${item.customerPhone.slice(-4)})
                  `}}
                </span>
              </template>
              <span>{{ item.customerPhone }}</span>
            </v-tooltip>
          </div>
        </div>
        <div class="tit-info" v-if="item.files !== null && item.files.length > 0">
          <div class="info-item">
            <v-chip small label outlined class="pr-2 pl-2 mr-1">첨부파일</v-chip>
            <v-chip
              v-for="(file, index) in item.files"
              :key="index"
              color="info"
              class="mr-1"
              small
              close
              close-icon="mdi-arrow-collapse-down"
              link
              @click="fileDownload(file)"
              @click:close="fileDownload(file)"
            >
              <span class="chip-max-width">
                {{ file.name }}
              </span>
            </v-chip>
          </div>
        </div>
      </div>
      <div class="content-wrap">
        <p class="content-tit">
          <v-icon color="primary" class="mr-1 mt-1">
            mdi-file-sign
          </v-icon>
          내용
        </p>
        <viewer
          v-if="show === true"
          :initialValue="item.content"
          height="500px"
        />
      </div>
      <div class="bottom-wrap">
        <div class="memo-wrap">
          <p class="content-tit">
            <v-icon color="primary" class="mr-1 mt-1">
              mdi-message-processing-outline
            </v-icon>
            댓글
            <v-chip
              v-if="item.memos != undefined"
              :color="item.memos.length > 0?'primary':''" x-small class="ml-1 pl-2 pr-2">
              {{ item.memos.length }}
            </v-chip>
          </p>
          <div class="memo-list" v-if="item.memos != undefined && item.memos.length > 0">
            <div class="memo" v-for="(memo, index) in item.memos" :key="index">
              <div class="memo-top">
                <div class="text">
                  <v-chip label small class="pr-2 pl-2">
                    {{
                      `${comm.getGroupFullPath(groups, memo.creatorGroupNo)} ${memo.creatorName}`
                    }}
                  </v-chip>
                  <v-icon color="primary" v-if="memo.receiver != null">mdi-arrow-right-thin</v-icon>
                  <v-chip
                    label color="primary" v-if="memo.receiver != null"
                    small class="pr-2 pl-2"
                  >
                    {{
                      `${comm.getGroupFullPath(groups, memo.receiverGroupNo)}
                        ${memo.receiverName}`
                    }}
                  </v-chip>
                  <span class="date ml-2">{{ time.makeLocalTime(memo.created) }}</span>
                </div>
                <v-chip
                  v-if="memo.files.length > 0"
                  color="info"
                  small
                  close
                  close-icon="mdi-arrow-collapse-down"
                  link
                  @click="fileDownload(memo.files[0])"
                  @click:close="fileDownload(memo.files[0])"
                >
                  <span class="chip-max-width">
                    {{ memo.files[0].name }}
                  </span>
                </v-chip>
              </div>
              <p class="memo-body">{{ memo.content }}</p>
            </div>
          </div>
          <div class="memo-write-wrap">
            <div class="left-wrap">
              <div class="receiver-file-wrap">
                <v-chip small class="mr-1" v-if="memoReceiver === null">수신인 없음</v-chip>
                <v-chip small class="mr-1" v-else color="warning"
                  @click="memoReceiver = null"
                  link
                  close
                  @click:close="memoReceiver = null"
                >
                  {{ memoReceiver.fullName }}
                </v-chip>
                <v-chip small class="mr-1" v-if="memoFiles === null">첨부파일 없음</v-chip>
                <v-chip small class="mr-1" v-else
                  color="info"
                  @click="memoFiles = null"
                  @click:close="memoFiles = null"
                  link
                  close
                >
                  {{ memoFiles.name }}
                </v-chip>
              </div>
              <v-textarea solo hide-details no-resize class="area"
                style="font-size:0.9rem"
                flat v-model="memoContent"
                value="댓글내용을 입력해주세요."
                rows="3"
              />
            </div>
            <div class="btn-wrap">
              <v-card class="receiver-picker"
                :class="type === 1 ? 'b-type' : ''"
                v-click-outside="onClickOutside"
                v-if="receiverPicker === true"
              >
                <v-text-field
                  :autofocus="true"
                  dense
                  flat
                  hide-details
                  v-model="staffsText"
                  placeholder="수신인 검색"
                  @input="staffsFiltering"
                  color="black"
                  outlined
                  solo
                ></v-text-field>
                <v-list v-if="staffsFilter.length > 0">
                  <v-list-item
                    v-for="(row, i) in staffsFilter"
                    :key="i"
                    dense
                    nav
                    link
                    @click="setReceiver(row)"
                  >
                    <v-list-item-title>
                      <v-chip small class="pr-1 pl-1 mr-1" link outlined label>
                        {{ row.group }}
                      </v-chip>
                      {{ row.name }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
                <v-list v-else-if="staffsFilter.length === 0">
                  <v-list-item dense>
                    <v-list-item-title>{{
                      staffsText == null
                      || staffsText.trim().length === 0
                      ?'검색어를 입력해주세요.'
                      :'데이터가 없습니다.'
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card>
              <v-tooltip right color="black">
                <template #activator="{ on, attrs }">
                  <v-btn depressed color="warning" fab small
                    :disabled="receiverPicker === true || memoReceiver !== null"
                    v-on="on"
                    v-bind="attrs"
                    @click="receiverPicker = true"
                  >
                    <v-icon>mdi-account-multiple-plus</v-icon>
                  </v-btn>
                </template>
                <span>수신인 선택</span>
              </v-tooltip>
              <v-tooltip right color="black">
                <template #activator="{ on, attrs }">
                  <v-btn depressed color="info" fab small
                    @click="fileClick"
                    v-on="on"
                    v-bind="attrs"
                    class="mt-1"
                    :disabled="memoFiles !== null"
                  >
                    <v-icon>mdi-paperclip</v-icon>
                  </v-btn>
                </template>
                <span>파일첨부</span>
              </v-tooltip>
              <v-tooltip right color="black">
                <template #activator="{ on, attrs }">
                  <v-btn color="primary" depressed class="mt-1"
                    fab small
                    @click="memoRegist"
                    v-on="on"
                    v-bind="attrs"
                    :disabled="memoContent === null || memoContent === ''"
                  >
                    <v-icon>mdi-send</v-icon>
                  </v-btn>
                </template>
                <span>댓글등록</span>
              </v-tooltip>
            </div>
            <input type="file" @change="memoFileSet" class="file-input" ref="fileInput">
          </div>
        </div>
        <div class="visit-wrap" v-if="type === 0">
          <p class="content-tit">
            <v-icon color="primary" class="mr-1 mt-1">
              mdi-clock-time-two-outline
            </v-icon>
            방문기록
          </p>
          <div class="visit"
            :class="item.memos.length > 0?'on':''"
            v-if="item.visitor != undefined && item.visitor.length > 0"
          >
            <p v-for="(row, index) in item.visitor" :key="index">
              {{ comm.getGroupFullPath(groups, row.groupNo, '')}} {{ row.name }}
              <span>{{ time.makeLocalTime(row.created) }}</span>
            </p>
          </div>
          <div class="visit-msg" v-else>방문기록이 없습니다.</div>
        </div>
      </div>
    </v-card>
    <dlg-history
      :show.sync="historyDlg.show"
      :data="historyDlg.data"
      :title="historyDlg.title"
    />
  </v-dialog>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex';
import '@toast-ui/editor/dist/toastui-editor-viewer.css';
import { Viewer } from '@toast-ui/vue-editor';
import comm from '@/util/comm';
import time from '@/util/time';
import fileUtils from '@/util/file';
import DlgHistory from '@/components/dialog/DlgHistory.vue';

export default {
  components: {
    Viewer,
    DlgHistory,
  },
  name: 'DlgPostView',
  props: {
    item: {
      type: Object,
    },
    show: {
      type: Boolean,
      required: true,
    },
    func: {
      type: Function,
      default: () => {},
    },
    customer: {
      type: String,
    },
    manager: {
      type: String,
    },
    type: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    time,
    comm,
    receiverPicker: false,
    fileUtils,
    memoContent: null,
    memoFiles: null,
    memoReceiver: null,
    groups: [],
    staffsText: null,
    staffs: [],
    staffsFilter: [],
    historyDlg: {
      show: false,
      data: [],
      title: '',
    },
  }),
  computed: {
    ...mapGetters({
      userInfo: 'auth/userInfo',
    }),
  },
  methods: {
    ...mapMutations({
      progress: 'dialog/progress',
      post: 'dialog/post',
    }),
    ...mapActions({
      alert: 'dialog/alert',
    }),
    init() {
      this.memoContent = null;
      this.memoReceiver = null;
      this.memoFiles = null;
      this.func();
      this.$emit('update:show', false);
    },
    staffsFiltering(e) {
      if (e != null && e.length > 0) {
        const result = this.staffs.filter((r) => r.fullName.indexOf(e) !== -1);
        this.staffsFilter = result;
      } else {
        this.staffsFilter = [];
      }
    },
    getStaffsList() {
      this.staffs = [];
      this.staffsFilter = [];
      this.staffsText = null;
      this.$socket.emit('users.staffs.list.get', {
        page: 1,
        itemsPerPage: 0,
        sortBy: [],
        sortDesc: [],
        filters: [],
      }, (resp) => {
        if (resp.result === 'success') {
          resp.items.forEach((r) => {
            const groupName = comm.getGroupFullPath(this.groups, r.groupNo, '그룹없음 ');
            const user = {
              id: r.id,
              fullName: `${groupName}${r.name}`,
              name: r.name,
              group: groupName,
            };
            this.staffs.push(user);
          });
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
        }
      });
    },
    getGroupList() {
      this.$socket.emit('groups.list.get', {
        page: 1,
        itemsPerPage: 0,
        sortBy: ['no'],
        sortDesc: [false],
        filters: [],
      }, (resp) => {
        if (resp.result === 'success') {
          this.groups = resp.items.groups;
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
        }
      });
    },
    setReceiver(staff = null) {
      this.memoReceiver = staff;
      this.receiverPicker = false;
    },
    fileClick() {
      this.$refs.fileInput.click();
    },
    fileRemove() {
      this.$refs.fileInput.value = null;
    },
    async memoFileSet(e) {
      this.progress(true);
      if (e.target.files.length > 1) {
        this.alert(['error', '첨부파일은 1개만 등록가능합니다.']);
      } else if (e.target.files.length > 0) {
        const sizeCheck = e.target.files[0].size < 10485760;
        if (sizeCheck === true) {
          const getFiles = await this.fileUtils.readFileToPayload(e.target.files[0]);
          this.memoFiles = getFiles;
        } else {
          this.alert(['error', '파일당 최대 10MB 까지 업로드 가능합니다.']);
        }
      }
      this.progress(false);
    },
    memoRegist() {
      this.progress(true);
      let command = '';
      let reqObj = {};
      if (this.type === 0) {
        command = 'cs.memo.add';
        reqObj = {
          csNo: this.item.no,
          content: this.memoContent,
          tag: this.memoReceiver !== null ? [this.memoReceiver.id] : [],
          files: this.memoFiles !== null ? [this.memoFiles] : [],
        };
      } else if (this.type === 1) {
        command = 'board.post.memo.add';
        reqObj = {
          postNo: this.item.no,
          content: this.memoContent,
          tag: this.memoReceiver !== null ? [this.memoReceiver.id] : [],
          files: this.memoFiles !== null ? [this.memoFiles] : [],
        };
      }
      this.$socket.emit(command, reqObj, (resp) => {
        if (resp.result === 'success') {
          this.alert(['success', '댓글이 등록되었습니다.']);
          this.memoContent = null;
          this.memoReceiver = null;
          this.memoFiles = null;
          this.contentsRefresh();
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.progress(false);
        }
      });
    },
    onClickOutside() {
      this.receiverPicker = false;
    },
    fileDownload(fileObj) {
      this.progress(true);
      this.$socket.emit('files.get', {
        no: fileObj.no,
        token: fileObj.token,
      }, async (resp) => {
        if (resp.result === 'success') {
          await fileUtils.writeFileFromPayload(resp.item);
          this.progress(false);
        } else if (resp.name === 'TokenExpiredError') {
          this.alert(['error', '파일 다운로드 시간이 만료되었습니다. 새로고침 후 다시 시도해주세요.']);
          this.progress(false);
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.progress(false);
        }
      });
    },
    updateStatus(no, status) {
      this.progress(true);
      this.$socket.emit('cs.update', {
        no,
        status,
      }, (resp) => {
        if (resp.result === 'success') {
          this.contentsRefresh();
          this.func();
          this.alert(['success', '처리상태가 변경되었습니다.']);
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.progress(false);
        }
      });
    },
    contentsRefresh() {
      this.progress(true);
      if (this.type === 0) {
        this.$socket.emit('cs.get', { no: this.item.no }, (resp) => {
          if (resp.result === 'success') {
            this.$emit('update:item', resp.item);
            this.progress(false);
          } else {
            this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
            console.error(resp);
            this.progress(false);
          }
        });
      } else if (this.type === 1) {
        this.$socket.emit('board.post.get', { no: this.item.no }, (resp) => {
          if (resp.result === 'success') {
            this.$emit('update:item', resp.item);
            this.progress(false);
          } else {
            this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
            console.error(resp);
            this.progress(false);
          }
        });
      }
    },
    getHistories(no) {
      this.progress(true);
      this.$socket.emit('log.get', {
        action: 'cs.update',
        key: String(no),
      }, (resp) => {
        if (resp.result === 'success') {
          const msgObj = {
            0: '미확인',
            1: '진행중',
            2: '처리완료',
          };
          const result = [];
          resp.items.forEach((r) => {
            if (r.after.status !== undefined) {
              const msg = msgObj[String(r.after.status)];
              result.push({ ...r, after: msg });
            }
          });
          this.historyDlg.data = result;
          this.historyDlg.show = true;
          this.historyDlg.title = '상담처리 이력';
          this.progress(false);
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.progress(false);
        }
      });
    },
    checkFuncPermission(funcName) {
      try {
        const userPermission = { ...this.userInfo.permission.cs.menu.cs.func };
        let result = false;
        if (
          userPermission[funcName] !== undefined
          && userPermission[funcName].bool === true
        ) result = true;
        return result;
      } catch (error) {
        console.error('checkFuncPermission error');
        return false;
      }
    },
  },
  mounted() {
  },
  watch: {
    show: {
      immediate: true,
      handler(val) {
        if (val === true) {
          this.getGroupList();
          this.getStaffsList();
        }
      },
    },
    memoContent: {
      handler() {
      },
    },
  },
};
</script>
<style lang="scss">
.width-fix-dialog {
  min-width: 1000px;
}
.title-bar {
  padding:10px 15px;
  .tit {
    display: flex;
    align-items: center;
    & > .v-chip--link {
      flex-shrink: 0;
    }
    .tit-text {
      font-size: 1.1rem;
      color:#000;
      font-weight: 600;
      margin:0;
      margin-left: 10px;;
      margin-right: 10px;;
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  .tit-info {
    display: flex;
    flex-wrap: wrap;
    .info-item {
      margin:4px;
      display: flex;
      align-items: center;
      margin-right: 14px;
      .info-name {
        font-size: .9rem;
      }
    }
  }
}
.content-tit {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom:4px;
  display: flex;
  align-items: center;
}
.bottom-wrap {
  display: flex;
  padding:20px;
  padding-top:10px;
  .memo-wrap {
    width: 100%;
    .chip-max-width{
      max-width: 100px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .memo-write-wrap {
      background-color: #f2f2f2;
      padding:10px;
      border-radius: 10px;
      margin-right: 2px;
      display: flex;
      align-items: flex-end;
      .left-wrap {
        width: 100%;
        .receiver-file-wrap {
          padding:0px 4px;
          margin-bottom:8px;
          width: 100%;
          display: flex;
          align-items: center;
          .name {
            font-size: .9rem;
          }
        }
      }
      .file-input {
        display: none;
      }
      .btn-wrap {
        margin-left: 8px;
        width: 50px;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-end;
        justify-content: center;
        position: relative;
        .receiver-picker {
          width: 250px;
          max-width: 250px;
          position: absolute;
          left: 50px;
          z-index: 1;
          top:0px;
          box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
          0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
          &.b-type {
            right: 50px;
            left: unset;
          }
          .v-list {
            max-height: 100px;
            overflow: auto;
          }
        }
      }
      .area {
        width: 100%;
        border:1px solid #ccc;
        background-color: #fff;
        &:focus {
          outline: none;
        }
      }
    }
    .memo-list {
      height: 150px;
      overflow: auto;
      padding-right:2px;
      padding:10px;
      background-color: #f2f2f2;
      border:1px solid #ccc;
      & + .memo-write-wrap {
        margin-top:10px;
      }
      .memo {
        background-color: #fff;
        padding:0px;
        border:1px solid #ccc;
        border-radius: 10px;
        &+.memo {
          margin-top:10px;
        }
        .memo-top {
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: space-between;
          // border-bottom:1px solid #ccc;
          padding:6px;
          padding-bottom:0;
          .text{
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            .date {
              font-size: .8rem;
              color: #333;
            }
          }
        }
        .memo-body {
          font-size: .8rem;
          color: #000;
          padding:6px;
          margin:0;
          white-space: pre-wrap;
        }
        .memo-file {
          display: flex;
          justify-content: flex-end;
          padding:6px;
        }
      }
    }
    &+.visit-wrap {
      margin-left: 15px;
    }
  }
  .visit-wrap {
    flex-shrink: 0;
    .visit-msg {
      padding:10px;
      background-color: #f2f2f2;
      font-size: .9rem;
      border-radius: 10px;
      min-width: 350px;
    }
    .visit {
      min-width: 350px;
      background-color: #f2f2f2;
      padding:10px;
      border-radius: 0px;
      height: 148px;
      overflow: auto;
      border: 1px solid #ccc;
      &.on {
        height: 308px;
      }
      p {
        margin:0px;
        width: 100%;
        justify-content: space-between;
        font-size: .8rem;
        display: flex;
        span {
          margin-left: 8px;
        }
        &+p {
          margin-top:2px;
        }
      }
    }
  }
}
.content-wrap {
  padding:10px 20px;
  border: 1px solid #ccc;
  border-right: 0;
  border-left: 0;
  overflow: auto;
  height: 100%;
  .content-row {
    display: flex;
    align-items: center;
  }
}
/* 24.06.26 */
.v-dialog--scrollable{
  flex-direction:column;/*높이값*/
  &.v-dialog--scrollable{
    .v-card{
      padding:0 24px;
      .v-card__title{
        padding-left:0;padding-right:0;
      }
    }
  }
  .v-card{
    padding:0 24px;
    /*.v-text-field input{
      color:rgba(0, 0, 0, 0.87);
      mia-height:32px;
      border:1px solid currentColor;
      transition-duration:0.15s;
      transition-property:color;
      transition-timing-function:cubic-bezier(0.25, 0.8, 0.25, 1);
      font-size:16px;
      border-radius:4px;
      padding-left:8px;
      padding-right:8px;
    }
    .v-text-field{
      .v-input__control{
        .v-input__slot:before{display:none;}
      }
    }*/
  }
}
/* 24.06.26 */
.v-dialog--scrollable{
  flex-direction:column;/*높이값*/
  &.v-dialog--scrollable{
    .v-card{
      padding:0 24px;
      .v-card__title{
        padding-left:0;padding-right:0;
      }
    }
  }
  .v-card{
    padding:0 24px;
    /*.v-text-field input{
      color:rgba(0, 0, 0, 0.87);
      mia-height:32px;
      border:1px solid currentColor;
      transition-duration:0.15s;
      transition-property:color;
      transition-timing-function:cubic-bezier(0.25, 0.8, 0.25, 1);
      font-size:16px;
      border-radius:4px;
      padding-left:8px;
      padding-right:8px;
    }
    .v-text-field{
      .v-input__control{
        .v-input__slot:before{display:none;}
      }
    }*/
  }
}
</style>
