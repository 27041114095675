import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import Home from '../views/Home.vue';
import SignIn from '../views/sign/SignIn.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: Home,
    meta: {
      needAuth: false,
      needLogin: true,
    },
    children: [
      {
        path: '/mypage',
        component: () => import(/* webpackChunkName: "mypage" */ '../views/MyPage.vue'),
        meta: {
          needAuth: false,
          needLogin: true,
        },
      },
      {
        path: '/notification',
        component: () => import(/* webpackChunkName: "notification" */ '../views/Notification.vue'),
        meta: {
          needAuth: false,
          needLogin: true,
        },
      },
      {
        path: '/ip',
        component: () => import(/* webpackChunkName: "ip" */ '../views/menus/Ip.vue'),
        meta: {
          needAuth: true,
          needLogin: true,
          category: 'admin',
          menu: 'ip',
        },
      },
      {
        path: '/account',
        component: () => import(/* webpackChunkName: "account" */ '../views/menus/account/Account.vue'),
        meta: {
          needAuth: true,
          needLogin: true,
          category: 'admin',
          menu: 'account',
        },
      },
      {
        path: '/account-form',
        component: () => import(/* webpackChunkName: "account-form" */ '../views/menus/account/AccountRegist.vue'),
        meta: {
          needAuth: true,
          needLogin: true,
          category: 'admin',
          menu: 'account',
          func: 'regist',
        },
      },
      {
        path: '/auth',
        component: () => import(/* webpackChunkName: "login" */ '../views/menus/AuthPreset.vue'),
        meta: {
          needAuth: true,
          needLogin: true,
          category: 'admin',
          menu: 'auth',
        },
      },
      {
        path: '/board',
        component: () => import(/* webpackChunkName: "board" */ '../views/menus/Board.vue'),
        meta: {
          needAuth: true,
          needLogin: true,
          category: 'admin',
          menu: 'board',
        },
      },
      {
        path: '/group',
        component: () => import(/* webpackChunkName: "group" */ '../views/menus/Group.vue'),
        meta: {
          needAuth: true,
          needLogin: true,
          category: 'admin',
          menu: 'group',
        },
      },
      {
        path: '/sourceManage',
        component: () => import(/* webpackChunkName: "source-manage" */ '../views/menus/SourceManage.vue'),
        meta: {
          needAuth: true,
          needLogin: true,
          category: 'admin',
          menu: 'sourceManage',
        },
      },
      {
        path: '/stateConfig',
        component: () => import(/* webpackChunkName: "state-config" */ '../views/menus/StateConfig.vue'),
        meta: {
          needAuth: true,
          needLogin: true,
          category: 'admin',
          menu: 'stateConfig',
        },
      },
      {
        path: '/ranking',
        component: () => import(/* webpackChunkName: "ranking" */ '../views/menus/ranking.vue'),
        meta: {
          needAuth: false,
          needLogin: true,
          category: 'admin',
          menu: 'ranking',
        },
      },
      {
        path: '/db',
        component: () => import(/* webpackChunkName: "db" */ '../views/menus/contact/Contact.vue'),
        meta: {
          needAuth: true,
          needLogin: true,
          category: 'cs',
          menu: 'db',
        },
      },
      {
        path: '/customer207',
        component: () => import(/* webpackChunkName: "customer207" */ '../views/menus/cs/Customer207.vue'),
        meta: {
          needAuth: true,
          needLogin: true,
          category: 'cs',
          menu: 'customer207',
        },
      },
      {
        path: '/customer208',
        component: () => import(/* webpackChunkName: "customer208" */ '../views/menus/cs/Customer208.vue'),
        meta: {
          needAuth: true,
          needLogin: true,
          category: 'cs',
          menu: 'customer208',
        },
      },
      {
        path: '/customer209',
        component: () => import(/* webpackChunkName: "customer209" */ '../views/menus/cs/Customer209.vue'),
        meta: {
          needAuth: true,
          needLogin: true,
          category: 'cs',
          menu: 'customer209',
        },
      },
      {
        path: '/customer240',
        component: () => import(/* webpackChunkName: "customer240" */ '../views/menus/cs/Customer240.vue'),
        meta: {
          needAuth: true,
          needLogin: true,
          category: 'cs',
          menu: 'customer240',
        },
      },
      {
        path: '/customer406',
        component: () => import(/* webpackChunkName: "customer406" */ '../views/menus/cs/Customer406.vue'),
        meta: {
          needAuth: true,
          needLogin: true,
          category: 'cs',
          menu: 'customer406',
        },
      },
      {
        path: '/customer407',
        component: () => import(/* webpackChunkName: "customer407" */ '../views/menus/cs/Customer407.vue'),
        meta: {
          needAuth: true,
          needLogin: true,
          category: 'cs',
          menu: 'customer407',
        },
      },
      {
        path: '/db-form',
        component: () => import(/* webpackChunkName: "db-form" */ '../views/menus/contact/ContactRegist.vue'),
        meta: {
          needAuth: true,
          needLogin: true,
          category: 'cs',
          menu: 'db',
          func: 'regist',
        },
      },
      {
        path: '/cs',
        component: () => import(/* webpackChunkName: "cs" */ '../views/menus/cs/Counseling.vue'),
        meta: {
          needAuth: true,
          needLogin: true,
          category: 'cs',
          menu: 'cs',
        },
      },
      {
        path: '/customer',
        component: () => import(/* webpackChunkName: "customer" */ '../views/menus/cs/Customer.vue'),
        meta: {
          needAuth: true,
          needLogin: true,
          category: 'cs',
          menu: 'customer',
        },
      },
      {
        path: '/customer-expired',
        component: () => import(/* webpackChunkName: "expired" */ '../views/menus/cs/ExpiredCustomer.vue'),
        meta: {
          needAuth: true,
          needLogin: true,
          category: 'cs',
          menu: 'customer',
        },
      },
      {
        path: '/customer-expected',
        component: () => import(/* webpackChunkName: "expected" */ '../views/menus/cs/ExpectedCustomer.vue'),
        meta: {
          needAuth: true,
          needLogin: true,
          category: 'cs',
          menu: 'customer',
        },
      },
      {
        path: '/product',
        component: () => import(/* webpackChunkName: "product" */ '../views/menus/cs/Product.vue'),
        meta: {
          needAuth: true,
          needLogin: true,
          category: 'cs',
          menu: 'product',
        },
      },
      {
        path: '/rooms',
        component: () => import(/* webpackChunkName: "rooms" */ '../views/menus/cs/Rooms.vue'),
        meta: {
          needAuth: true,
          needLogin: true,
          category: 'cs',
          menu: 'rooms',
        },
      },
      {
        path: '/sales',
        component: () => import(/* webpackChunkName: "sales" */ '../views/menus/sales/Sales.vue'),
        meta: {
          needAuth: true,
          needLogin: true,
          category: 'sales',
          menu: 'sales',
        },
      },
      {
        path: '/pg',
        component: () => import(/* webpackChunkName: "pg" */ '../views/menus/Pg.vue'),
        meta: {
          needAuth: true,
          needLogin: true,
          category: 'admin',
          menu: 'pg',
        },
      },
      {
        path: '/smsApiLog',
        component: () => import(/* webpackChunkName: "smsApiLog" */ '../views/menus/LogSMS.vue'),
        meta: {
          needAuth: true,
          needLogin: true,
          category: 'admin',
          menu: 'smsApiLog',
        },
      },
      {
        path: '/smsApiLog',
        component: () => import(/* webpackChunkName: "smsApiLog" */ '../views/menus/LogSMS.vue'),
        meta: {
          needAuth: true,
          needLogin: true,
          category: 'admin',
          menu: 'smsApiLog',
        },
      },
      {
        path: '/reportInsert',
        component: () => import(/* webpackChunkName: "reportInsert" */ '../views/menus/report/Insert.vue'),
        meta: {
          needAuth: true,
          needLogin: true,
          category: 'admin',
          menu: 'reportInsert',
        },
      },
      {
        path: '/reportView',
        component: () => import(/* webpackChunkName: "reportView" */ '../views/menus/report/Read.vue'),
        meta: {
          needAuth: true,
          needLogin: true,
          category: 'admin',
          menu: 'reportView',
        },
      },
      {
        path: '/reportDB',
        component: () => import(/* webpackChunkName: "reportDB" */ '../views/menus/report/DBManage.vue'),
        meta: {
          needAuth: true,
          needLogin: true,
          category: 'admin',
          menu: 'reportDB',
        },
      },
      {
        path: '/reportGraph',
        component: () => import(/* webpackChunkName: "reportGraph" */ '../views/menus/report/Graph.vue'),
        meta: {
          needAuth: true,
          needLogin: true,
          category: 'admin',
          menu: 'reportGraph',
        },
      },
      {
        path: '/salary',
        component: () => import(/* webpackChunkName: "salary" */ '../views/menus/finance/Salary.vue'),
        meta: {
          needAuth: true,
          needLogin: true,
          category: 'finance',
          menu: 'salary',
        },
      },
      {
        path: '/statistics',
        component: () => import(/* webpackChunkName: "statistics" */ '../views/menus/CustomerStats.vue'),
        meta: {
          needAuth: true,
          needLogin: true,
          category: 'admin',
          menu: 'statistics',
        },
      },
      {
        path: '/boards-:boardNo',
        component: () => import(/* webpackChunkName: "boards" */ '../views/menus/Boards.vue'),
        meta: {
          needAuth: false,
          needLogin: true,
          category: 'boards',
        },
        props: (route) => ({ boardNo: Number(route.params.boardNo) }),
      },
      {
        path: '/manager-customer',
        component: () => import(/* webpackChunkName: "manager-customer" */ '../views/menus/manager/ManagerCustomer.vue'),
        meta: {
          needAuth: false,
          needLogin: true,
        },
      },
      {
        path: '/manager-sales',
        component: () => import(/* webpackChunkName: "manager-sales" */ '../views/menus/manager/ManagerSales.vue'),
        meta: {
          needAuth: false,
          needLogin: true,
        },
      },
      // 나의 급여 관리
      {
        path: '/manager-salary',
        component: () => import('../views/menus/manager/ManagerSalary.vue'),
        meta: {
          needAuth: false,
          needLogin: true,
        },
      },
      {
        path: '/leader-customer',
        component: () => import(/* webpackChunkName: "leader-customer" */ '../views/menus/manager/LeaderCustomer.vue'),
        meta: {
          needAuth: false,
          needLogin: true,
        },
      },
      {
        path: '/leader-sales',
        component: () => import(/* webpackChunkName: "leader-sales" */ '../views/menus/manager/LeaderSales.vue'),
        meta: {
          needAuth: false,
          needLogin: true,
        },
      },
    ],
  },
  {
    path: '/signin',
    name: 'SignIn',
    meta: {
      needAuth: false,
      needLogin: false,
    },
    component: SignIn,
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      needAuth: false,
      needLogin: false,
    },
    component: () => import(/* webpackChunkName: "login" */ '../views/login/Login.vue'),
  },
  {
    path: '/ranking-view',
    component: () => import(/* webpackChunkName: "ranking-view" */ '../views/menus/sales/rankingview.vue'),
    meta: {
      needAuth: false,
      needLogin: false,
      category: 'sales',
      menu: 'sales',
    },
  },
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {},
});

router.beforeEach(async (to, from, next) => {
  // 허용되지 않은 경로 블럭
  if (!to.matched.length) {
    store.dispatch('dialog/alert', ['error', '잘못된 접근입니다.']);
    router.push('/');
    next(false);
  }
  next();
});

router.afterEach(async (to) => {
  store.commit('router/route', to);
  await router.checkAuth();
});

router.checkAuth = async () => {
  const to = router.currentRoute;
  const connected = store.getters['auth/connected'];
  const initialized = store.getters['auth/initialized'];
  const verified = store.getters['auth/verified'];
  const userInfo = store.getters['auth/userInfo'];
  const companyInfo = store.getters['auth/companyInfo'];
  const permission = userInfo ? userInfo.permission : null;

  if (connected && initialized) {
    if (!verified && to.meta.needLogin) {
      await router.push('/signin');
    } else if (verified && to.path === '/signin' && to.path !== '/') {
      if (permission.admin.menu.dashboard.bool === true) {
        await router.push('/boards-0');
      } else {
        await router.push('/');
      }
    } else if (verified && to.meta.needAuth) {
      if (to.meta.category !== 'boards') {
        const authBool = to.meta.func === undefined
          ? permission[to.meta.category].menu[to.meta.menu].bool
          : permission[to.meta.category].menu[to.meta.menu].func[to.meta.func].bool;
        if (authBool === false) {
          store.dispatch('dialog/alert', ['error', '접근 권한이 없습니다.'], false);
          router.back();
        }
      }
    }
  } else if (companyInfo.code !== '') {
    localStorage.removeItem(`MANOadmin.${companyInfo.code}.authToken`);
    if (to.path !== '/signin' && to.meta.needLogin) {
      await router.push('/signin');
    }
  }
};
router.goBack = () => {
  router.back();
};
// 전역 참조 허용 함수
(() => {
  window.$router = {
    goBack: router.goBack,
  };
})();

export default router;
